import React from 'react'
import { ContentFeatureProps } from './ContentFeature.types'
import './styles.scss'

import Img from 'gatsby-image/withIEPolyfill'
import Button from '@mrs-ui/Button'

const ContentFeature: React.FC<ContentFeatureProps> = ({ ...props }) => {
  return (
    <div className="content-feature__container">
      {props.image && (
        <div className="content-feature__img-container">
          <div className="content-feature__ic__inner">
            <Img
              fluid={props.image}
              aria-hidden={true}
              objectFit="cover"
              objectPosition="100% 100%"
              className="content-feature__img"
            />
          </div>
        </div>
      )}
      {props.icon && !props.image && (
        <div className="content-feature__icon-container">{props.icon}</div>
      )}
      <div className="pb-gap-compact">
        <h3>{props.title}</h3>
        <div className="text-small">{props.excerpt}</div>
      </div>
      {props.link && (
        <div>
          <Button link={props.link} appearance="link">
            {props.linkText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ContentFeature
