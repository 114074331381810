import { Container, Section } from '@components/common'
import ContactForm from '@components/ContactForm'
import React from 'react'

const VideoSection = () => {
  return (
    <Section verticalSpacing="xl">
      <Container>
        <div className="flex items-center justify-center">
          <iframe
            width="420"
            height="315"
            src="https://www.youtube.com/embed/yKTSLffVGbk"
          ></iframe>
        </div>
      </Container>
    </Section>
  )
}
export default VideoSection
